import React, { FC } from 'react';
import { graphql } from 'gatsby';

import ProductListingComponent from 'layout/ProductListingComponent';

import { ProductListingPageProps } from './models';

const ProductListingPage: FC<ProductListingPageProps> = ({ data: { productsListingPage } }) => (
  <ProductListingComponent node={productsListingPage} />
);

export const query = graphql`
  query ($url: String) {
    productsListingPage(url: { eq: $url }) {
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      url
      purchaseLinkText
      title
      titlePage
      section {
        title
        extraClass
        products {
          name
          url
          targetProduct
          cssExtraClass
          basicInfo {
            name
            image {
              altText
              childImageSharp {
                gatsbyImageData(width: 300, height: 300)
              }
            }
            concentration
            age
            flavor
            activeIngredient {
              activeIngredient
            }
          }
        }
      }
      productMainBanner {
        image {
          altText
          childImageSharp {
            gatsbyImageData
          }
        }
        imageMobile {
          altText
          childImageSharp {
            gatsbyImageData
          }
        }
        description
        hasText
      }
    }
  }
`;

export default ProductListingPage;
