import React, { FC } from 'react';
import { Link } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Image from 'common/Image';

import { ProductListProps } from './models';

import './productListComponent.scss';

const ProductListComponent: FC<ProductListProps> = ({ product }) => {
  const { basicInfo, url } = product;
  const { name, image } = basicInfo[0];
  const { activeIngredient } = basicInfo[0].activeIngredient[0];

  return (
    <>
      <Link to={url} className="product__link">
        <div className="product__target">{product.targetProduct}</div>

        <div className="product__image">
          <Image imageData={image} alt={image.altText} />
        </div>

        <div className="product__title">
          <DangerouslySetInnerHtml className="title" html={name} />
        </div>

        <div className="product__body">
          <p>{activeIngredient}</p>
        </div>
      </Link>
    </>
  );
};

export default ProductListComponent;
