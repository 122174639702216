import React, { FC } from 'react';

import { DangerouslySetInnerHtml, Layout } from 'layout';
import ProductListComponent from 'layout/ProductListComponent';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { ProductListingProps } from './models';

import './productListingComponent.scss';

const ProductListingComponent: FC<ProductListingProps> = ({
  node: {
    productMainBanner: { image, description, imageMobile },
    section,
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    purchaseLinkText,
    titlePage,
  },
}) => (
  <Layout>
    <Seo {...{ title: seoMetaTitle, description: seoMetaDescription, keywords: seoMetaKeywords }} />
    <PageSchema
      type="WebPage"
      name={seoMetaTitle}
      data={{
        metaTitle: seoMetaTitle,
        metaDescription: seoMetaDescription,
        metaKeywords: seoMetaKeywords,
      }}
    />
    <div className="products">
      <div className="banner__hero">
        <Image className="w-100 d-none d-md-block" imageData={image} alt={image.altText} />
        <Image className="w-100 d-md-none" imageData={imageMobile} alt={imageMobile.altText} />
        <DangerouslySetInnerHtml className="banner__title" html={description} />
      </div>

      <div className="content container">
        <h2 className="products__title-page">{titlePage}</h2>

        {section.map(({ title, extraClass, products }) => (
          <div key={title} className={`row products__row  ${extraClass}`}>
            <div className="col-12">
              <h3 className="products__title-section" key={title}>
                {title}
              </h3>
            </div>

            {products.map((product) => {
              const { name } = product;

              return (
                <article className="products__col col-12 col-sm-6 col-md-4" key={name}>
                  <div className={`products__item ${product.cssExtraClass || ''}`}>
                    <ProductListComponent product={product} {...{ purchaseLinkText }} />
                  </div>
                </article>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  </Layout>
);

export default ProductListingComponent;
